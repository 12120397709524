import React, { useContext } from "react";
import Logo from "../../assets/TSG-Logo.png";
import { Auth } from "aws-amplify";
import { Button } from "@mui/material";
import { AccessTokenContext } from "../../App";

const Header = () => {
  const { email, name } = useContext(AccessTokenContext);

  async function signOut() {
    return Auth.signOut();
  }

  return (
    <div className="header-container ">
      <img src={Logo} alt="TSG-Logo" className="header-image" />
      <div className="name-signOut">
        {name} ({email})
        <Button className="btn btn-sign-out" onClick={signOut}>
          Sign Out
        </Button>
      </div>
    </div>
  );
};

export default Header;
