import { CircularProgress, Stack } from "@mui/material";
import React from "react";

export const Spinner = () => {
  return (
    <Stack sx={{ color: "#fc671a" }} spacing={2} direction="row">
      <CircularProgress color="inherit" size={64} thickness={5} />
    </Stack>
  );
};
