import * as yup from "yup";

export const validationSchema = yup.object({
  customerType: yup.string().required("Customer Type is required"),

  customerId: yup
    .string()
    .max(20, "Customer Id must be 20 characters or less")
    .required("Customer ID is required"),
  cardNumber: yup.string().required("Card Number is required"),
  phone: yup
    .string()
    .required("Phone Number is required")
    .max(25, "Phone Number must be 25 characters or less"),
  cvv: yup.string().required("CVV is required"),
  firstName: yup
    .string()
    .required("First name is required")
    .max(50, "First name must be 50 characters or less"),
  lastName: yup
    .string()
    .required("Last name is required")
    .max(50, "Last name must be 50 characters or less"),
  address: yup
    .string()
    .required("Address is required")
    .max(60, "Address must be 60 characters or less"),
  city: yup
    .string()
    .required("City is required")
    .max(40, "City must be 40 characters or less"),
  state: yup.string().required("State is required"),
  zipCode: yup.string().required("Zip code is required"),
  country: yup.string().required("Country is required"),
  company: yup.string().max(50, "Company must be 50 characters or less"),
  expirationDate: yup
    .string()
    .required("Card Expiration Date is required")
    .test("custom-validation", "Invalid Expiration date", (value) => {
      const month = Number(value.split("/")[0]);
      const year = value.split("/")[1];
      const today = new Date();
      const correctMonth = month < 13;
      const sameYear = today.getFullYear() === Number(`20${year}`);
      const expiresOnAnotherYear = today.getFullYear() < Number(`20${year}`);
      const expiresOnAnotherMonth = today.getMonth() + 1 < month;

      if (correctMonth && expiresOnAnotherYear) {
        return true;
      } else if (sameYear) {
        if (expiresOnAnotherMonth && correctMonth) {
          return true;
        }
      }
      return false;
    }),
  invoiceNumber: yup
    .string()
    .required("Invoice Number is required")
    .max(20, "Invoice Number must be 20 characters or less"),
  amount: yup
    .string()
    .required("Amount is required")
    .test(
      "valid-amount",
      "Value cannot be greater than $9,999,999,999,999.99",
      (value, context) => {
        // We remove the thousand separators and cast it to Number.
        const valueAmount = +value.replaceAll(",", "");
        if (isNaN(valueAmount)) {
          return context.createError({
            message: "Value should be a valid currency amount",
          });
        }

        if (valueAmount < 0) {
          return context.createError({ message: "Amount must be positive" });
        }

        return valueAmount <= 9999999999999.99;
      }
    ),
});
