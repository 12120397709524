import axios from "axios";
import { IAuthorizeCC } from "./AuthorizeCreditCard";

export const createCustomerProfile = async (
  body: IAuthorizeCC,
  access_token: string
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_PUBLIC_URL}/customer-profile/create`,
    {
      creditCardInfo: {
        cardNumber: body.cardNumber,
        expirationDate: body.expirationDate,
        cvc: body.cvv,
      },
      customerBillingInformation: {
        firstName: body.firstName,
        lastName: body.lastName,
        company: body.company,
        address: body.address,
        city: body.city,
        state: body.state,
        zipCode: body.zipCode,
        country: body.country,
        phone: body.phone,
        fax: body.fax,
      },
      shippingInformation: {
        shippingFirstName: body.shippingFirstName,
        shippingLastName: body.shippingLastName,
        shippingCompany: body.shippingCompany,
        shippingAddress: body.shippingAddress,
        shippingCity: body.shippingCity,
        shippingState: body.shippingState,
        shippingZipCode: body.shippingZipCode,
        shippingCountry: body.shippingCountry,
        shippingPhone: body.shippingPhone,
        shippingFax: body.shippingFax,
      },
      ICustomerInformation: {
        customerId: body.customerId,
        description: "",
        email: body.email,
        customerType: body.customerType.toLowerCase(),
      },
    },
    {
      headers: {
        Authorization: `${access_token}`,
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
    }
  );
  return response.data;
};
