import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@mui/material";
import { CSSProperties } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
export interface ICustomDropdown {
  name: string;
  placeholder?: string;
  options: any;
  customDropdownStyles: CSSProperties;
  customOptionsStyles?: CSSProperties;
  focusedBorderColor?: string;
  isDisabled?: boolean;
  value?: any;
  onChange?: any;
  inputLabel?: string;
  register: any;
  dropdownValue?: string;
  resetDropdown?: boolean;
  error?: any;
  helperText?: string;
}

const Dropdown = (props: ICustomDropdown) => {
  const {
    placeholder,
    options,
    isDisabled,
    inputLabel,
    customOptionsStyles,
    customDropdownStyles,
    register,
    name,
    dropdownValue,
    resetDropdown,
    error,
    helperText,
  } = props;
  const [value, setValue] = useState("");

  const generateOptions = () => {
    let dropdownList =
      options.length > 0 &&
      options.map((item: any, i: number) => {
        return (
          <MenuItem
            style={{ ...customOptionsStyles }}
            key={i}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        );
      });
    return dropdownList;
  };

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  useEffect(() => {
    if (resetDropdown) {
      setValue("");
    }
  }, [resetDropdown]);

  const handleChange = (event: any): void => {
    let val = event.target.value;

    setValue(val);
  };

  return (
    <>
      <FormControl style={{ width: "100%" }} error={error}>
        {inputLabel && (
          <InputLabel
            style={{
              fontWeight: 600,
              color: `rgba(0, 0, 0, 0.54)`,
              marginTop: 6,
            }}
            className="label"
            id="label"
          >
            {inputLabel}
          </InputLabel>
        )}
        <Select
          key={name}
          displayEmpty
          defaultValue={resetDropdown ? "" : undefined}
          {...register(name)}
          name={name}
          label={inputLabel}
          placeholder="Select country"
          className="country-dropdown"
          value={dropdownValue !== "" ? dropdownValue : value}
          onChange={handleChange}
          variant="outlined"
          disabled={isDisabled}
          IconComponent={ArrowDropDownIcon}
          labelId="label"
          inputProps={{
            style: {
              fontFamily: `proxima-nova, sans-serif`,
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: placeholder ? "86px" : "50px",
                margin: "0px",
                fontFamily: `proxima-nova, sans-serif !important`,
              },
            },
          }}
          style={{
            fontFamily: `proxima-nova, sans-serif !important`,
            color: "rgba(0, 0, 0, 0.87)",
            maxHeight: "250px",
            ...customDropdownStyles,
          }}
        >
          {generateOptions()}
        </Select>
        {!isDisabled && error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default Dropdown;
