import "./style/index.scss";
import "@aws-amplify/ui-react/styles.css";

import {
  Authenticator,
  Image,
  ThemeProvider,
  useTheme,
  View,
} from "@aws-amplify/ui-react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Amplify, Auth } from "aws-amplify";
import jwt_decode from "jwt-decode";
import InvoicePayment from "pages/InvoicePayment/InvoicePayment";
import { SuccessfulCreditCardCharge } from "pages/SuccessfulCreditCardCharge/SuccessfulCreditCardCharge";
import { createContext } from "react";
import React from "react";
import { AdobeFonts } from "react-adobe-fonts";
import { Route, Routes } from "react-router-dom";
import routes from "routes/routes";

import logo from "./assets/TSG-Logo-big.png";
import awsExports from "./aws-exports";
import ToastrComponent from "./components/Toastr/ToastrComponent";
import CreditCardCapture, {
  showErrorToast,
} from "./pages/CreditCardCapture/CreditCardCapture";
import { SuccessfulAuthorization } from "./pages/SuccessfulAuthorization/SuccessfulAuthorization";

Amplify.configure(awsExports);
//Auth.configure({storage : window.sessionStorage})

interface IJwtObject {
  exp: number;
}
export interface IContext {
  name: string | undefined;
  email: string | undefined;
  accessToken: string | undefined;
}

export const AccessTokenContext = createContext<IContext>({
  name: "",
  email: "",
  accessToken: "",
});

const headerComponent = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="TSG Logos" src={logo}></Image>
      </View>
    );
  },
};

const theme = {
  name: "TSG",
  typography: {
    fontFamily: `proxima-nova, sans-serif !important`,
  },
  tokens: {
    colors: {
      brand: {
        primary: {
          "10": "#f2f4f7",
          "80": "#ff5100",
          "90": "#e64a01",
          "100": "#202945",
        },
      },
    },
  },
};

export default function App() {
  React.useEffect(() => {
    checkJWT();
  }, []);

  const checkJWT = async () => {
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      const userSession = user?.getSignInUserSession();
      const jwtToken = userSession?.getIdToken().getJwtToken();
      const now = Math.floor(Date.now() / 1000);

      if (jwtToken) {
        const expiration: IJwtObject = jwt_decode(jwtToken);
        if (expiration.exp <= now) {
          showErrorToast("Your session has expired, please log in again.");
          Auth.signOut();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AdobeFonts kitId="fru2htc" />
      <ThemeProvider theme={theme}>
        <ToastrComponent />
        <Authenticator components={headerComponent}>
          {({ signOut, user }) => (
            <AccessTokenContext.Provider
              value={{
                name: user?.attributes?.name,
                email: user?.attributes?.email,
                accessToken: user
                  ?.getSignInUserSession()
                  ?.getAccessToken()
                  .getJwtToken(),
              }}
            >
              <div className="app">
                <Routes>
                  <Route
                    path={routes.creditCardCapture}
                    element={<CreditCardCapture />}
                  />
                  <Route
                    path={routes.successfulAuthorization}
                    element={<SuccessfulAuthorization />}
                  />
                  <Route
                    path={routes.successfulCCCharge}
                    element={<SuccessfulCreditCardCharge />}
                  />
                   <Route
                    path={routes.exemptedPayment}
                    element={<InvoicePayment />}
                  />
                  <Route path={routes.root} element={<InvoicePayment />} />
                </Routes>
              </div>
            </AccessTokenContext.Provider>
          )}
        </Authenticator>
      </ThemeProvider>
    </>
  );
}
