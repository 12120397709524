import { CCChargeFeePercent } from "./constValues";

/**
 * Method to calculate amount Fee and grant Total
 * @param amount From the form values
 * @returns numeric value of chargeFee and totalCharge
 */
export const calculateFeeAndTotal = (amount: string) => {
  let formAmount: number | null = +amount.replaceAll(",", "");
  if (isNaN(formAmount)) {
    formAmount = null;
  }

  const chargeFee = formAmount ? formAmount * CCChargeFeePercent : null;
  const totalCharge =
    typeof formAmount === "number" && typeof chargeFee === "number"
      ? formAmount + chargeFee
      : null;

  return { chargeFee, totalCharge };
};
